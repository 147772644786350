<script setup lang="ts">
const emit = defineEmits(['buttonClicked'])

type PropsType = {
  tag?: string,
  to?: string,
  disabled?: boolean,
  view?: string,
  loading?: boolean
}

const prosp = withDefaults(defineProps<PropsType>(), {
  tag: 'button',
  disabled: false,
  view: 'white',
  loading: false,
  to: "/"
})

const isDisabled = computed(() => prosp.disabled)

const tagValue = computed(() =>
  prosp.tag == 'link' && isDisabled.value == true
    ? 'button'
    : prosp.tag
)

const onClick = (event: MouseEvent) => {
  if (isDisabled.value == false) 
    emit('buttonClicked', event)
}
</script>

<template lang="pug">
template(v-if="tagValue == 'link' && isDisabled == false")
  NuxtLink.button(
    :to="to"
    :disabled="isDisabled"
    :class="[`view-${view}`, { disabled: isDisabled }]"
    @click="onClick"
  )
    Loading(v-if="loading")
    slot(name="icon-prefix")
    span.button-text: slot
    slot(name="icon-suffix")
template(v-else)
  component.button(
    :is="tagValue"
    :disabled="isDisabled"
    :class="[`view-${view}`, { disabled: isDisabled }]"
    @click="onClick"
  )
    Loading(v-if="loading")
    slot(name="icon-prefix")
    span.button-text: slot
    slot(name="icon-suffix")
</template>

<style lang="stylus" scoped>
.button
  --loading--scale .3
  @apply rounded-full px-5 py-2 border
  @apply flex items-center justify-center gap-2
  @apply transition-all duration-200
  @apply font-semibold
  &.disabled
    @apply cursor-default font-light
  span
    @apply whitespace-nowrap

.button.view-white
  @apply text-gray-800 bg-white border-transparent
  &:hover:not(.disabled)
    @apply border-gray-300 bg-gray-100 text-gray-800
  &.disabled
    @apply bg-gray-200 text-gray-500 

.button.view-gray
  @apply text-gray-900 bg-gray-400 border-transparent
  &:hover:not(.disabled)
    @apply border-gray-300 bg-gray-100 text-gray-800
  &.disabled
    @apply bg-gray-400 text-gray-600

.button.view-blue
  --loading-color #fff
  @apply text-white bg-blue-500 border-transparent font-medium
  &:hover:not(.disabled)
    @apply border-blue-950 bg-blue-700
  &.disabled
    @apply bg-gray-300 text-gray-600
</style>
